<!-- 导入前页面 -->
<template>
  <div class="importModule_main">
    <div class="header">
      <span class="tip1">{{ $t("label.import.index.introduce") }}</span>
      <!--通过简单三步，导入您的数据 -->
    </div>

    <div class="preparediv">
      <div class="prepareleft">
        <span class="prepareword">{{
          $t("label.import.index.advancepreparation")
        }}</span>
        <!-- 预先准备 -->
      </div>
      <div class="preparecenter">
        <dl>
          <dd style="text-align: center">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-csv1"></use>
            </svg>
          </dd>
          <dt>{{ $t("label.import.index.preparedata") }}</dt>
          <!-- 准备您要导入的数据 -->
        </dl>
      </div>
      <div class="prepareright">
        <div class="description">
          {{ $t("label.import.index.notice") }}
          <!--注：-->
        </div>
        <div class="des">
          <div class="description">
            {{
              $t("label.import.step1.csvonly", {
                recordnumlimit: this.$store.state.numlimit,
              })
            }}<!--1、只支持csv格式的文件，最多单次导入30000条记录（<span style="color: red">若需执行触发器，则最多导入2000条</span>），超出限制的记录，系统会自动忽略。若需导入更多数据，请先拆分为多个文件，分次导入。-->
          </div>
          <div class="description">
            {{ $t("label.import.index.beforeexport")
            }}<!--2、导入之前，请先确保导入其相关的所有记录。例如：如果您当前导入的对象与联系人之间存在查找关系，请先导入这些联系人，然后再导入您的数据。-->
          </div>
          <div class="description">
            {{ $t("label.import.index.chooseidorother")
            }}<!--3、可以选择使用id或名称匹配记录与填写查找字段。若需获取对应记录id等信息，可创建报表，并将记录名称与记录id设置为组列，再将报表导出。-->
          </div>
        </div>
      </div>
    </div>

    <div class="leg">
      <div class="legleft">
        <span class="legword">{{
          $t("label.import.index.processimport")
        }}</span>
        <!-- 进行导入 -->
      </div>

      <div class="legright">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-selecting"></use>
        </svg>
        <span class="progress">{{
          $t("label.import.index.selectobjectandfiles")
        }}</span>
        <!-- 选择对象与文件-->
        <span class="yuandian">…………………………………</span>
        <svg class="icon" aria-hidden="true"><use href="#icon-edit"></use></svg>
        <span class="progress">{{ $t("label.import.index.editfield") }}</span>
        <!--  编辑字段映射 -->
        <span class="yuandian">…………………………………</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-importing"></use>
        </svg>
        <span class="progress">{{ $t("label.import.index.startimport") }}</span>
        <!-- 开始导入 -->
      </div>
    </div>

    <!-- 导入按钮 -->
    <div class="ankle">
      <el-button class="elbutton" @click="startimport">
        <span class="worddaoru">{{ $t("label.import.index.startimport") }}</span
        ><!-- 开始导入 -->
      </el-button>
    </div>

    <div class="footer">
      <div class="footerup">
        <span>{{ $t("label.data.importef") }}</span>
        <!--导入失败的文件 -->
      </div>

      <!-- 展示数据的表格 -->
      <div class="table">
        <el-table
          :data="tableData"
          :header-cell-style="{
            color: '#080707',
            'font-size': '14px',
            'font-family': 'SourceHanSansCN-Medium',
            'letter-spacing': '0',
          }"
          style="width: 100%"
        >
          <el-table-column
            prop="createID"
            :label="$t('label.import.index.createid')"
            align="center"
            width="300"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.createID }}</div>
            </template>
          </el-table-column>

          <el-table-column
            prop="filename"
            align="center"
            :label="$t('label.import.index.filename')"
          >
          </el-table-column>

          <el-table-column
            prop="createDate"
            align="center"
            :label="$t('label.import.index.starttime')"
          >
          </el-table-column>

          <el-table-column
            align="center"
            :label="$t('label_partner_all_operate')"
          >
            <!--操作-->
            <!-- <el-button @click="handleClick(scope.row)"
              >导出</el-button
            > -->

            <template slot-scope="scope">
              <el-button @click="downloadFailFile(scope.row)"
                >{{ $t("label.reportexport")
                }}<!--导出--></el-button
              >
            </template>
          </el-table-column>
          <!-- //接口未返回此列数据，暂时关闭 -->
          <!-- <el-table-column
        prop="zhaungtai"
        label="状态"
        align="center"
        width="80">
      </el-table-column> -->

          <!-- <el-table-column
        prop="chuangjianjilu"
        align="center"
        label="创建记录">
      </el-table-column> -->
          <!-- <el-table-column
        prop="gengxinjilu"
        align="center"
        label="更新记录">
      </el-table-column> -->

          <!-- //接口未返回此列数据，暂时关闭  -->
          <!-- <el-table-column
        prop="shibai"
        align="center"
        label="失败">
      </el-table-column> -->
          <!-- <el-table-column
        prop="hulue"
        align="center"
        label="忽略">
      </el-table-column> -->

          <!-- <el-table-column
        prop="haoshi"
        align="center"
        label="耗时">
      </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 导入组件
 */
import { getMyDownPage, downloadFailFile } from "./api";
export default {
  components: {},
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    // 查询导入失败的文件
    this.getMyDownPage();
  },
  computed: {},
  watch: {},
  methods: {
    //查询导入失败的文件
    async getMyDownPage() {
      let params = {
        shownumber: "50",
        showpage: 1,
        downtype: "import",
      };
      let res = await getMyDownPage(params);
      this.tableData = res.data.filelist.map((item) => {
        return {
          createID: item.createById,
          filename: item.name,
          createDate: item.createDate,
          fid: item.id,
          auth: res.data.auth,
          oid: res.data.orgid,
        };
      });
    },

    //下载失败的文件
    async downloadFailFile(row) {
      let params = {
        fid: row.fid,
        auth: row.auth,
        oid: row.oid,
        accessToken: this.$CCDK.CCToken.getToken(),
      };
      downloadFailFile(params).then((res) => {
        var link = document.createElement("a");
        link.setAttribute("download", row.filename);
        link.href = `data:text/csv;charset=utf-8,${res}`;
        link.click();
      });
    },

    startimport() {
      this.$router.push(`/importModule/step1`);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-cascader__dropdown {
  display: none !important;
}

.importModule_main {
  width: 98.5%;
  height: 100%;
  // padding: 10px;
  margin: 10px auto;
  background-color: #ffffff;
  // display: flex;
  border-radius: 4px;

  .header {
    width: 100%;
    height: 46px;
    padding: 10px 0 0 0.7%;
  }
  .tip1 {
    font-family: SourceHanSansCN-Medium;
    font-size: 18px;
    color: #080707;
    letter-spacing: 0;
  }
  .preparediv {
    width: 100%;
    height: 136px;
    display: flex;
  }
  .prepareleft {
    height: 136px;
    width: 21.8%;
    margin-left: 0.7%;
  }
  .prepareword {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    line-height: 130px;
  }
  .preparecenter {
    margin-right: 64px;
    svg {
      width: 80px;
      height: 90px;
    }
  }
  .prepareright {
    width: 58%;
    height: 136px;
  }
  .description {
    text-indent: -20px;
    margin-left: 25px;
    margin-bottom: 0;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #666666;
    letter-spacing: 0;
  }

  dt {
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
  }
  .leg {
    width: 100%;
    height: 136px;
    display: flex;
  }
  .legleft {
    height: 136px;
    width: 21.8%;
    margin-left: 0.7%;
  }
  .legword {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    line-height: 130px;
  }

  .legright {
    // width: 47.9%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .yuandian {
    color: #000000;
    font-size: 16px;
    position: relative;
    top: -5px;
  }
  .description {
    text-indent: -22px;
    margin-left: 40px;
  }
  dt {
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
  }
  .progress {
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: #006dcc;
    letter-spacing: 0;
    margin: 0 5px 0 5px;
    white-space: nowrap;
  }
  .legright svg {
    width: 36px;
    height: 36px;
    margin-left: 6px;
  }
  .ankle {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .elbutton {
    background: #006dcc;
    border-radius: 3px;
    border-radius: 3px;
    width: 176px;
    height: 50px;
    margin: auto;
  }
  .worddaoru {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
  }
  .footerup {
    height: 50px;
    width: 100%;
    margin: 20px 0 0 0.7%;
    span {
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
  }
  ::v-deep .el-table__header-wrapper {
    border: 1px solid #c8c6c6;
    width: 100%;
  }

  ::v-deep .table {
    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          .has-gutter {
            tr {
              th {
                border-right: 1px solid #c8c6c6;
                // &:first-child{border: none;}
              }
            }
          }
        }
      }
    }
  }
}
</style>
